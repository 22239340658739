*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Inter var, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

[type="text"], input:where(:not([type])), [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, input:where(:not([type])):focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
  text-align: inherit;
}

::-webkit-datetime-edit {
  display: inline-flex;
}

::-webkit-datetime-edit {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-year-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-month-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-day-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-hour-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-minute-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-second-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-millisecond-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple], [size]:where(select:not([size="1"])) {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  color: #2563eb;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

@media (forced-colors: active) {
  [type="checkbox"]:checked {
    appearance: auto;
  }
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

@media (forced-colors: active) {
  [type="radio"]:checked {
    appearance: auto;
  }
}

[type="checkbox"]:checked:hover, [type="checkbox"]:checked:focus, [type="radio"]:checked:hover, [type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

@media (forced-colors: active) {
  [type="checkbox"]:indeterminate {
    appearance: auto;
  }
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px solid buttontext;
  outline: 1px auto -webkit-focus-ring-color;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.form-input, .form-textarea, .form-select, .form-multiselect {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.form-input:focus, .form-textarea:focus, .form-select:focus, .form-multiselect:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

.form-input::placeholder, .form-textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

.form-input::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

.form-input::-webkit-date-and-time-value {
  min-height: 1.5em;
  text-align: inherit;
}

.form-input::-webkit-datetime-edit {
  display: inline-flex;
}

.form-input::-webkit-datetime-edit {
  padding-top: 0;
  padding-bottom: 0;
}

.form-input::-webkit-datetime-edit-year-field {
  padding-top: 0;
  padding-bottom: 0;
}

.form-input::-webkit-datetime-edit-month-field {
  padding-top: 0;
  padding-bottom: 0;
}

.form-input::-webkit-datetime-edit-day-field {
  padding-top: 0;
  padding-bottom: 0;
}

.form-input::-webkit-datetime-edit-hour-field {
  padding-top: 0;
  padding-bottom: 0;
}

.form-input::-webkit-datetime-edit-minute-field {
  padding-top: 0;
  padding-bottom: 0;
}

.form-input::-webkit-datetime-edit-second-field {
  padding-top: 0;
  padding-bottom: 0;
}

.form-input::-webkit-datetime-edit-millisecond-field {
  padding-top: 0;
  padding-bottom: 0;
}

.form-input::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

.prose {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.prose :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.prose :where(a):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline;
}

.prose :where(strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.prose :where(a strong):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(blockquote strong):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(thead th strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-inline-start: 1.625em;
  list-style-type: decimal;
}

.prose :where(ol[type="A"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="A" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="I"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="I" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="1"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: decimal;
}

.prose :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-inline-start: 1.625em;
  list-style-type: disc;
}

.prose :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.prose :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.prose :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.25em;
  font-weight: 600;
}

.prose :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.prose :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-inline-start-width: .25rem;
  border-inline-start-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-inline-start: 1em;
  font-style: italic;
  font-weight: 500;
}

.prose :where(blockquote p:first-of-type):not(:where([class~="not-prose"], [class~="not-prose"] *)):before {
  content: open-quote;
}

.prose :where(blockquote p:last-of-type):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: close-quote;
}

.prose :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.prose :where(h1 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 900;
}

.prose :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33333;
}

.prose :where(h2 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 800;
}

.prose :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.6;
}

.prose :where(h3 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.prose :where(h4 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
  display: block;
}

.prose :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-kbd);
  box-shadow: 0 0 0 1px rgb(var(--tw-prose-kbd-shadows) / 10%), 0 3px 0 rgb(var(--tw-prose-kbd-shadows) / 10%);
  padding-top: .1875em;
  padding-inline-end: .375em;
  padding-bottom: .1875em;
  border-radius: .3125rem;
  padding-inline-start: .375em;
  font-family: inherit;
  font-size: .875em;
  font-weight: 500;
}

.prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):before, .prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: "`";
}

.prose :where(a code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h1 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-size: .875em;
}

.prose :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-size: .9em;
}

.prose :where(h4 code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(blockquote code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(thead th code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  padding-top: .857143em;
  padding-inline-end: 1.14286em;
  padding-bottom: .857143em;
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding-inline-start: 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.prose :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: #0000;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.prose :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)):before, .prose :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: none;
}

.prose :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  table-layout: auto;
  width: 100%;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.prose :where(thead):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.prose :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-inline-end: .571429em;
  padding-bottom: .571429em;
  padding-inline-start: .571429em;
  font-weight: 600;
}

.prose :where(tbody tr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.prose :where(tbody tr:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 0;
}

.prose :where(tbody td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  vertical-align: baseline;
}

.prose :where(tfoot):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-top-width: 1px;
  border-top-color: var(--tw-prose-th-borders);
}

.prose :where(tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  vertical-align: top;
}

.prose :where(th, td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  text-align: start;
}

.prose :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-kbd: #111827;
  --tw-prose-kbd-shadows: 17 24 39;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-kbd: #fff;
  --tw-prose-invert-kbd-shadows: 255 255 255;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.prose :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.prose :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-start: .375em;
}

.prose :where(.prose > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(.prose > ul > li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ul > li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(.prose > ol > li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ol > li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .5em;
  padding-inline-start: 1.625em;
}

.prose :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-start: 0;
}

.prose :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-end: 0;
}

.prose :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-top: .571429em;
  padding-inline-end: .571429em;
  padding-bottom: .571429em;
  padding-inline-start: .571429em;
}

.prose :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-start: 0;
}

.prose :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-end: 0;
}

.prose :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(.prose > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(.prose > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 0;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.-bottom-2 {
  bottom: -.5rem;
}

.-bottom-52 {
  bottom: -13rem;
}

.-right-\[45px\] {
  right: -45px;
}

.-right-\[5px\] {
  right: -5px;
}

.-top-\[5px\] {
  top: -5px;
}

.bottom-0 {
  bottom: 0;
}

.bottom-5 {
  bottom: 1.25rem;
}

.bottom-\[15px\] {
  bottom: 15px;
}

.bottom-\[18px\] {
  bottom: 18px;
}

.bottom-\[20px\] {
  bottom: 20px;
}

.bottom-\[30px\] {
  bottom: 30px;
}

.bottom-\[35px\] {
  bottom: 35px;
}

.bottom-\[40px\] {
  bottom: 40px;
}

.bottom-\[45px\] {
  bottom: 45px;
}

.bottom-\[8px\] {
  bottom: 8px;
}

.bottom-\[90px\] {
  bottom: 90px;
}

.bottom-full {
  bottom: 100%;
}

.left-0 {
  left: 0;
}

.left-3 {
  left: .75rem;
}

.left-\[-10px\] {
  left: -10px;
}

.left-\[-30px\] {
  left: -30px;
}

.left-\[-385px\] {
  left: -385px;
}

.left-\[-390px\] {
  left: -390px;
}

.left-\[-40px\] {
  left: -40px;
}

.left-\[130px\] {
  left: 130px;
}

.left-\[13px\] {
  left: 13px;
}

.left-\[150px\] {
  left: 150px;
}

.left-\[15px\] {
  left: 15px;
}

.left-\[160px\] {
  left: 160px;
}

.left-\[185px\] {
  left: 185px;
}

.left-\[20px\] {
  left: 20px;
}

.left-\[250px\] {
  left: 250px;
}

.left-\[25px\] {
  left: 25px;
}

.left-\[290px\] {
  left: 290px;
}

.left-\[30px\] {
  left: 30px;
}

.left-\[40px\] {
  left: 40px;
}

.left-\[425px\] {
  left: 425px;
}

.left-\[600px\] {
  left: 600px;
}

.left-\[640px\] {
  left: 640px;
}

.left-\[80px\] {
  left: 80px;
}

.right-0 {
  right: 0;
}

.right-10 {
  right: 2.5rem;
}

.right-2 {
  right: .5rem;
}

.right-5 {
  right: 1.25rem;
}

.right-6 {
  right: 1.5rem;
}

.right-\[10px\] {
  right: 10px;
}

.right-\[210px\] {
  right: 210px;
}

.right-\[25px\] {
  right: 25px;
}

.right-\[30px\] {
  right: 30px;
}

.right-\[60px\] {
  right: 60px;
}

.top-0 {
  top: 0;
}

.top-1 {
  top: .25rem;
}

.top-1\/2 {
  top: 50%;
}

.top-10 {
  top: 2.5rem;
}

.top-2 {
  top: .5rem;
}

.top-36 {
  top: 9rem;
}

.top-6 {
  top: 1.5rem;
}

.top-\[-50px\] {
  top: -50px;
}

.top-\[10px\] {
  top: 10px;
}

.top-\[113px\] {
  top: 113px;
}

.top-\[115px\] {
  top: 115px;
}

.top-\[11px\] {
  top: 11px;
}

.top-\[130px\] {
  top: 130px;
}

.top-\[140px\] {
  top: 140px;
}

.top-\[150px\] {
  top: 150px;
}

.top-\[15px\] {
  top: 15px;
}

.top-\[20px\] {
  top: 20px;
}

.top-\[27px\] {
  top: 27px;
}

.top-\[28px\] {
  top: 28px;
}

.top-\[290px\] {
  top: 290px;
}

.top-\[340px\] {
  top: 340px;
}

.top-\[390px\] {
  top: 390px;
}

.top-\[40px\] {
  top: 40px;
}

.top-\[425px\] {
  top: 425px;
}

.top-\[460px\] {
  top: 460px;
}

.top-\[470px\] {
  top: 470px;
}

.top-\[560px\] {
  top: 560px;
}

.top-\[5px\] {
  top: 5px;
}

.top-\[60px\] {
  top: 60px;
}

.top-\[610px\] {
  top: 610px;
}

.top-\[63px\] {
  top: 63px;
}

.top-\[75px\] {
  top: 75px;
}

.isolate {
  isolation: isolate;
}

.-z-10 {
  z-index: -10;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.float-right {
  float: right;
}

.-m-3 {
  margin: -.75rem;
}

.m-auto {
  margin: auto;
}

.-mx-1\.5 {
  margin-left: -.375rem;
  margin-right: -.375rem;
}

.-my-1\.5 {
  margin-top: -.375rem;
  margin-bottom: -.375rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-psc-base {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-1\.5 {
  margin-top: .375rem;
  margin-bottom: .375rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-psc-2xl {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-psc-base {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-psc-lg {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.-mb-1 {
  margin-bottom: -.25rem;
}

.-mb-px {
  margin-bottom: -1px;
}

.-ml-1\.5 {
  margin-left: -.375rem;
}

.-ml-px {
  margin-left: -1px;
}

.-mt-5 {
  margin-top: -1.25rem;
}

.mb-0\.5 {
  margin-bottom: .125rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-1\.5 {
  margin-bottom: .375rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-2\.5 {
  margin-bottom: .625rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-\[400px\] {
  margin-bottom: 400px;
}

.mb-psc-2xl {
  margin-bottom: 2.5rem;
}

.mb-psc-base {
  margin-bottom: 1rem;
}

.mb-psc-lg {
  margin-bottom: 1.5rem;
}

.mb-psc-sm {
  margin-bottom: .5rem;
}

.mb-psc-xl {
  margin-bottom: 2rem;
}

.mb-psc-xs {
  margin-bottom: .125rem;
}

.ml-1\.5 {
  margin-left: .375rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-\[1\.2rem\] {
  margin-left: 1.2rem;
}

.ml-\[65px\] {
  margin-left: 65px;
}

.ml-auto {
  margin-left: auto;
}

.ml-psc-base {
  margin-left: 1rem;
}

.ml-psc-lg {
  margin-left: 1.5rem;
}

.ml-psc-sm {
  margin-left: .5rem;
}

.ml-psc-xl {
  margin-left: 2rem;
}

.ml-psc-xs {
  margin-left: .125rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-1\.5 {
  margin-right: .375rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-2\.5 {
  margin-right: .625rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-3\.5 {
  margin-right: .875rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mr-7 {
  margin-right: 1.75rem;
}

.mr-\[10px\] {
  margin-right: 10px;
}

.mr-psc-base {
  margin-right: 1rem;
}

.mr-psc-sm {
  margin-right: .5rem;
}

.mr-psc-xs {
  margin-right: .125rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-1\.5 {
  margin-top: .375rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-2\.5 {
  margin-top: .625rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-56 {
  margin-top: 14rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-\[100px\] {
  margin-top: 100px;
}

.mt-\[130px\] {
  margin-top: 130px;
}

.mt-\[150px\] {
  margin-top: 150px;
}

.mt-\[1px\] {
  margin-top: 1px;
}

.mt-\[60px\] {
  margin-top: 60px;
}

.mt-\[80px\] {
  margin-top: 80px;
}

.mt-psc-base {
  margin-top: 1rem;
}

.mt-psc-lg {
  margin-top: 1.5rem;
}

.mt-psc-sm {
  margin-top: .5rem;
}

.mt-psc-xl {
  margin-top: 2rem;
}

.mt-psc-xs {
  margin-top: .125rem;
}

.box-border {
  box-sizing: border-box;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-52 {
  height: 13rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-\[10px\] {
  height: 10px;
}

.h-\[175px\] {
  height: 175px;
}

.h-\[180px\] {
  height: 180px;
}

.h-\[19px\] {
  height: 19px;
}

.h-\[1px\] {
  height: 1px;
}

.h-\[220px\] {
  height: 220px;
}

.h-\[225px\] {
  height: 225px;
}

.h-\[22px\] {
  height: 22px;
}

.h-\[25px\] {
  height: 25px;
}

.h-\[260px\] {
  height: 260px;
}

.h-\[300px\] {
  height: 300px;
}

.h-\[30px\] {
  height: 30px;
}

.h-\[33px\] {
  height: 33px;
}

.h-\[35px\] {
  height: 35px;
}

.h-\[60px\] {
  height: 60px;
}

.h-\[65px\] {
  height: 65px;
}

.h-\[70px\] {
  height: 70px;
}

.h-\[75px\] {
  height: 75px;
}

.h-\[80px\] {
  height: 80px;
}

.h-\[816px\] {
  height: 816px;
}

.h-\[860px\] {
  height: 860px;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-60 {
  max-height: 15rem;
}

.max-h-\[15em\] {
  max-height: 15em;
}

.max-h-\[200px\] {
  max-height: 200px;
}

.min-h-\[285px\] {
  min-height: 285px;
}

.min-h-full {
  min-height: 100%;
}

.w-0 {
  width: 0;
}

.w-1 {
  width: .25rem;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-1\/6 {
  width: 16.6667%;
}

.w-10 {
  width: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.w-12 {
  width: 3rem;
}

.w-36 {
  width: 9rem;
}

.w-4 {
  width: 1rem;
}

.w-4\/12 {
  width: 33.3333%;
}

.w-5 {
  width: 1.25rem;
}

.w-52 {
  width: 13rem;
}

.w-6 {
  width: 1.5rem;
}

.w-64 {
  width: 16rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.w-\[100\%\] {
  width: 100%;
}

.w-\[1000px\] {
  width: 1000px;
}

.w-\[103px\] {
  width: 103px;
}

.w-\[1056px\] {
  width: 1056px;
}

.w-\[160px\] {
  width: 160px;
}

.w-\[16px\] {
  width: 16px;
}

.w-\[170px\] {
  width: 170px;
}

.w-\[180px\] {
  width: 180px;
}

.w-\[190px\] {
  width: 190px;
}

.w-\[200px\] {
  width: 200px;
}

.w-\[20px\] {
  width: 20px;
}

.w-\[22px\] {
  width: 22px;
}

.w-\[25px\] {
  width: 25px;
}

.w-\[280px\] {
  width: 280px;
}

.w-\[300px\] {
  width: 300px;
}

.w-\[310px\] {
  width: 310px;
}

.w-\[320px\] {
  width: 320px;
}

.w-\[325px\] {
  width: 325px;
}

.w-\[355px\] {
  width: 355px;
}

.w-\[35px\] {
  width: 35px;
}

.w-\[365px\] {
  width: 365px;
}

.w-\[400px\] {
  width: 400px;
}

.w-\[415px\] {
  width: 415px;
}

.w-\[600px\] {
  width: 600px;
}

.w-\[70px\] {
  width: 70px;
}

.w-\[910px\] {
  width: 910px;
}

.w-\[950px\] {
  width: 950px;
}

.w-\[990px\] {
  width: 990px;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.min-w-\[200px\] {
  min-width: 200px;
}

.min-w-full {
  min-width: 100%;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-sm {
  max-width: 24rem;
}

.flex-1 {
  flex: 1;
}

.flex-none {
  flex: none;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow, .grow {
  flex-grow: 1;
}

.table-auto {
  table-layout: auto;
}

.table-fixed {
  table-layout: fixed;
}

.border-collapse {
  border-collapse: collapse;
}

.origin-top-left {
  transform-origin: 0 0;
}

.origin-top-right {
  transform-origin: 100% 0;
}

.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-5 {
  --tw-translate-x: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-2 {
  --tw-translate-y: .5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-4 {
  --tw-translate-y: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes ping {
  75%, 100% {
    opacity: 0;
    transform: scale(2);
  }
}

.animate-ping {
  animation: 1s cubic-bezier(0, 0, .2, 1) infinite ping;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-default {
  cursor: default;
}

.cursor-no-drop {
  cursor: no-drop;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.select-text {
  -webkit-user-select: text;
  user-select: text;
}

.resize-none {
  resize: none;
}

.resize {
  resize: both;
}

.list-inside {
  list-style-position: inside;
}

.list-outside {
  list-style-position: outside;
}

.list-disc {
  list-style-type: disc;
}

.appearance-none {
  appearance: none;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.place-items-center {
  place-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-1\.5 {
  gap: .375rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-psc-sm {
  gap: .5rem;
}

.gap-x-6 {
  column-gap: 1.5rem;
}

.gap-y-0\.5 {
  row-gap: .125rem;
}

.-space-x-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1px * var(--tw-space-x-reverse));
  margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.375rem * var(--tw-space-x-reverse));
  margin-left: calc(.375rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-psc-sm > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0px * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]), .space-y-psc-base > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-psc-lg > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
}

.divide-\[\#a49f98\] > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(164 159 152 / var(--tw-divide-opacity, 1));
}

.divide-\[\#dedcd9\] > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(222 220 217 / var(--tw-divide-opacity, 1));
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity, 1));
}

.divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-divide-opacity, 1));
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-\[20px\] {
  border-radius: 20px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-b-2xl {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.rounded-b-xl {
  border-bottom-right-radius: .75rem;
  border-bottom-left-radius: .75rem;
}

.rounded-l-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.rounded-r-lg {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-\[3px\] {
  border-width: 3px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-b-4 {
  border-bottom-width: 4px;
}

.border-b-\[1px\] {
  border-bottom-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-dashed {
  border-style: dashed;
}

.border-none {
  border-style: none;
}

.border-\[\#00a480\]\/50 {
  border-color: #00a48080;
}

.border-\[\#1E3A8A\] {
  --tw-border-opacity: 1;
  border-color: rgb(30 58 138 / var(--tw-border-opacity, 1));
}

.border-\[\#1F4388\] {
  --tw-border-opacity: 1;
  border-color: rgb(31 67 136 / var(--tw-border-opacity, 1));
}

.border-\[\#E15B2A\] {
  --tw-border-opacity: 1;
  border-color: rgb(225 91 42 / var(--tw-border-opacity, 1));
}

.border-\[\#a49f98\] {
  --tw-border-opacity: 1;
  border-color: rgb(164 159 152 / var(--tw-border-opacity, 1));
}

.border-amber-500 {
  --tw-border-opacity: 1;
  border-color: rgb(245 158 11 / var(--tw-border-opacity, 1));
}

.border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity, 1));
}

.border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgb(37 99 235 / var(--tw-border-opacity, 1));
}

.border-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgb(29 78 216 / var(--tw-border-opacity, 1));
}

.border-blue-800 {
  --tw-border-opacity: 1;
  border-color: rgb(30 64 175 / var(--tw-border-opacity, 1));
}

.border-blue-900 {
  --tw-border-opacity: 1;
  border-color: rgb(30 58 138 / var(--tw-border-opacity, 1));
}

.border-cyan-500 {
  --tw-border-opacity: 1;
  border-color: rgb(6 182 212 / var(--tw-border-opacity, 1));
}

.border-emerald-500 {
  --tw-border-opacity: 1;
  border-color: rgb(16 185 129 / var(--tw-border-opacity, 1));
}

.border-fuchsia-500 {
  --tw-border-opacity: 1;
  border-color: rgb(217 70 239 / var(--tw-border-opacity, 1));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity, 1));
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity, 1));
}

.border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity, 1));
}

.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgb(34 197 94 / var(--tw-border-opacity, 1));
}

.border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity, 1));
}

.border-lime-500 {
  --tw-border-opacity: 1;
  border-color: rgb(132 204 22 / var(--tw-border-opacity, 1));
}

.border-lime-600 {
  --tw-border-opacity: 1;
  border-color: rgb(101 163 13 / var(--tw-border-opacity, 1));
}

.border-lime-800 {
  --tw-border-opacity: 1;
  border-color: rgb(63 98 18 / var(--tw-border-opacity, 1));
}

.border-orange-500 {
  --tw-border-opacity: 1;
  border-color: rgb(249 115 22 / var(--tw-border-opacity, 1));
}

.border-pink-500 {
  --tw-border-opacity: 1;
  border-color: rgb(236 72 153 / var(--tw-border-opacity, 1));
}

.border-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgb(168 85 247 / var(--tw-border-opacity, 1));
}

.border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgb(252 165 165 / var(--tw-border-opacity, 1));
}

.border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity, 1));
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity, 1));
}

.border-rose-500 {
  --tw-border-opacity: 1;
  border-color: rgb(244 63 94 / var(--tw-border-opacity, 1));
}

.border-sky-500 {
  --tw-border-opacity: 1;
  border-color: rgb(14 165 233 / var(--tw-border-opacity, 1));
}

.border-slate-200 {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity, 1));
}

.border-slate-900 {
  --tw-border-opacity: 1;
  border-color: rgb(15 23 42 / var(--tw-border-opacity, 1));
}

.border-stone-200 {
  --tw-border-opacity: 1;
  border-color: rgb(231 229 228 / var(--tw-border-opacity, 1));
}

.border-stone-300 {
  --tw-border-opacity: 1;
  border-color: rgb(214 211 209 / var(--tw-border-opacity, 1));
}

.border-teal-500 {
  --tw-border-opacity: 1;
  border-color: rgb(20 184 166 / var(--tw-border-opacity, 1));
}

.border-transparent {
  border-color: #0000;
}

.border-violet-500 {
  --tw-border-opacity: 1;
  border-color: rgb(139 92 246 / var(--tw-border-opacity, 1));
}

.border-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgb(234 179 8 / var(--tw-border-opacity, 1));
}

.border-b-lime-600 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(101 163 13 / var(--tw-border-opacity, 1));
}

.border-b-transparent {
  border-bottom-color: #0000;
}

.border-t-gray-600 {
  --tw-border-opacity: 1;
  border-top-color: rgb(75 85 99 / var(--tw-border-opacity, 1));
}

.\!bg-blue-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity, 1)) !important;
}

.bg-\[\#009B77\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 155 119 / var(--tw-bg-opacity, 1));
}

.bg-\[\#009CBD\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 156 189 / var(--tw-bg-opacity, 1));
}

.bg-\[\#00a480\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 164 128 / var(--tw-bg-opacity, 1));
}

.bg-\[\#00a480\]\/25 {
  background-color: #00a48040;
}

.bg-\[\#00a480\]\/50 {
  background-color: #00a48080;
}

.bg-\[\#059a7b\] {
  --tw-bg-opacity: 1;
  background-color: rgb(5 154 123 / var(--tw-bg-opacity, 1));
}

.bg-\[\#059a7b\]\/25 {
  background-color: #059a7b40;
}

.bg-\[\#059a7b\]\/50 {
  background-color: #059a7b80;
}

.bg-\[\#1E3A8A\] {
  --tw-bg-opacity: 1;
  background-color: rgb(30 58 138 / var(--tw-bg-opacity, 1));
}

.bg-\[\#1F4388\] {
  --tw-bg-opacity: 1;
  background-color: rgb(31 67 136 / var(--tw-bg-opacity, 1));
}

.bg-\[\#1c4489\] {
  --tw-bg-opacity: 1;
  background-color: rgb(28 68 137 / var(--tw-bg-opacity, 1));
}

.bg-\[\#1c4489\]\/25 {
  background-color: #1c448940;
}

.bg-\[\#1c4489\]\/50 {
  background-color: #1c448980;
}

.bg-\[\#33b997\] {
  --tw-bg-opacity: 1;
  background-color: rgb(51 185 151 / var(--tw-bg-opacity, 1));
}

.bg-\[\#33b997\]\/25 {
  background-color: #33b99740;
}

.bg-\[\#33b997\]\/50 {
  background-color: #33b99780;
}

.bg-\[\#41aa88\] {
  --tw-bg-opacity: 1;
  background-color: rgb(65 170 136 / var(--tw-bg-opacity, 1));
}

.bg-\[\#97D700\] {
  --tw-bg-opacity: 1;
  background-color: rgb(151 215 0 / var(--tw-bg-opacity, 1));
}

.bg-\[\#E15B2A\] {
  --tw-bg-opacity: 1;
  background-color: rgb(225 91 42 / var(--tw-bg-opacity, 1));
}

.bg-\[\#E56A54\] {
  --tw-bg-opacity: 1;
  background-color: rgb(229 106 84 / var(--tw-bg-opacity, 1));
}

.bg-\[\#E6A65D\] {
  --tw-bg-opacity: 1;
  background-color: rgb(230 166 93 / var(--tw-bg-opacity, 1));
}

.bg-\[\#FEF1EC\] {
  --tw-bg-opacity: 1;
  background-color: rgb(254 241 236 / var(--tw-bg-opacity, 1));
}

.bg-\[\#d8dc3e\] {
  --tw-bg-opacity: 1;
  background-color: rgb(216 220 62 / var(--tw-bg-opacity, 1));
}

.bg-\[\#f3a00f\] {
  --tw-bg-opacity: 1;
  background-color: rgb(243 160 15 / var(--tw-bg-opacity, 1));
}

.bg-\[\#f3a00f\]\/25 {
  background-color: #f3a00f40;
}

.bg-\[\#f3a00f\]\/50 {
  background-color: #f3a00f80;
}

.bg-\[\#f78e39\] {
  --tw-bg-opacity: 1;
  background-color: rgb(247 142 57 / var(--tw-bg-opacity, 1));
}

.bg-\[\#f78e39\]\/25 {
  background-color: #f78e3940;
}

.bg-\[\#f78e39\]\/50 {
  background-color: #f78e3980;
}

.bg-\[\#f97316\] {
  --tw-bg-opacity: 1;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity, 1));
}

.bg-\[\#f97316\]\/25 {
  background-color: #f9731640;
}

.bg-\[\#f97316\]\/50 {
  background-color: #f9731680;
}

.bg-amber-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 243 199 / var(--tw-bg-opacity, 1));
}

.bg-amber-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 158 11 / var(--tw-bg-opacity, 1));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1));
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity, 1));
}

.bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity, 1));
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity, 1));
}

.bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity, 1));
}

.bg-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 58 138 / var(--tw-bg-opacity, 1));
}

.bg-cyan-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(207 250 254 / var(--tw-bg-opacity, 1));
}

.bg-cyan-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity, 1));
}

.bg-emerald-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 250 229 / var(--tw-bg-opacity, 1));
}

.bg-emerald-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(16 185 129 / var(--tw-bg-opacity, 1));
}

.bg-fuchsia-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 232 255 / var(--tw-bg-opacity, 1));
}

.bg-fuchsia-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(217 70 239 / var(--tw-bg-opacity, 1));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1));
}

.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity, 1));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
}

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity, 1));
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity, 1));
}

.bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 244 / var(--tw-bg-opacity, 1));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity, 1));
}

.bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity, 1));
}

.bg-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 101 52 / var(--tw-bg-opacity, 1));
}

.bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 231 255 / var(--tw-bg-opacity, 1));
}

.bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity, 1));
}

.bg-lime-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 252 203 / var(--tw-bg-opacity, 1));
}

.bg-lime-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(247 254 231 / var(--tw-bg-opacity, 1));
}

.bg-lime-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(132 204 22 / var(--tw-bg-opacity, 1));
}

.bg-lime-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(101 163 13 / var(--tw-bg-opacity, 1));
}

.bg-lime-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(63 98 18 / var(--tw-bg-opacity, 1));
}

.bg-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 237 213 / var(--tw-bg-opacity, 1));
}

.bg-orange-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 247 237 / var(--tw-bg-opacity, 1));
}

.bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity, 1));
}

.bg-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 231 243 / var(--tw-bg-opacity, 1));
}

.bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 72 153 / var(--tw-bg-opacity, 1));
}

.bg-ps-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(28 68 137 / var(--tw-bg-opacity, 1));
}

.bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 232 255 / var(--tw-bg-opacity, 1));
}

.bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(233 213 255 / var(--tw-bg-opacity, 1));
}

.bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity, 1));
}

.bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(126 34 206 / var(--tw-bg-opacity, 1));
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity, 1));
}

.bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity, 1));
}

.bg-red-50\/50 {
  background-color: #fef2f280;
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity, 1));
}

.bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity, 1));
}

.bg-rose-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 228 230 / var(--tw-bg-opacity, 1));
}

.bg-rose-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 63 94 / var(--tw-bg-opacity, 1));
}

.bg-sky-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 242 254 / var(--tw-bg-opacity, 1));
}

.bg-sky-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(14 165 233 / var(--tw-bg-opacity, 1));
}

.bg-slate-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity, 1));
}

.bg-slate-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity, 1));
}

.bg-slate-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity, 1));
}

.bg-slate-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity, 1));
}

.bg-stone-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 244 / var(--tw-bg-opacity, 1));
}

.bg-stone-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(231 229 228 / var(--tw-bg-opacity, 1));
}

.bg-stone-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 249 / var(--tw-bg-opacity, 1));
}

.bg-stone-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(120 113 108 / var(--tw-bg-opacity, 1));
}

.bg-teal-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(204 251 241 / var(--tw-bg-opacity, 1));
}

.bg-teal-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(20 184 166 / var(--tw-bg-opacity, 1));
}

.bg-transparent {
  background-color: #0000;
}

.bg-violet-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(237 233 254 / var(--tw-bg-opacity, 1));
}

.bg-violet-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(139 92 246 / var(--tw-bg-opacity, 1));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 249 195 / var(--tw-bg-opacity, 1));
}

.bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 179 8 / var(--tw-bg-opacity, 1));
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.bg-\[url\(\'\.\.\/\.\.\/gpt\/client\/assets\/dupi-single-arrow-bg\.svg\'\)\] {
  background-image: url("dupi-single-arrow-bg.16ed9868.svg");
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.p-1 {
  padding: .25rem;
}

.p-1\.5 {
  padding: .375rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-\[0\.45rem\] {
  padding: .45rem;
}

.p-psc-base {
  padding: 1rem;
}

.p-psc-lg {
  padding: 1.5rem;
}

.p-psc-sm {
  padding: .5rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-0\.5 {
  padding-left: .125rem;
  padding-right: .125rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-psc-2xl {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-psc-base {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-psc-lg {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.py-\[0\.55rem\] {
  padding-top: .55rem;
  padding-bottom: .55rem;
}

.py-psc-6xl {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
}

.py-psc-base {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-psc-lg {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-psc-sm {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-2\.5 {
  padding-bottom: .625rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pb-psc-lg {
  padding-bottom: 1.5rem;
}

.pb-psc-sm {
  padding-bottom: .5rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pl-9 {
  padding-left: 2.25rem;
}

.pl-\[10px\] {
  padding-left: 10px;
}

.pl-psc-base {
  padding-left: 1rem;
}

.pl-psc-sm {
  padding-left: .5rem;
}

.pr-1 {
  padding-right: .25rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-14 {
  padding-right: 3.5rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-2\.5 {
  padding-right: .625rem;
}

.pr-20 {
  padding-right: 5rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-3\.5 {
  padding-right: .875rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-40 {
  padding-right: 10rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pr-psc-base {
  padding-right: 1rem;
}

.pr-psc-sm {
  padding-right: .5rem;
}

.pt-0\.5 {
  padding-top: .125rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-psc-2xl {
  padding-top: 2.5rem;
}

.pt-psc-base {
  padding-top: 1rem;
}

.pt-psc-lg {
  padding-top: 1.5rem;
}

.pt-psc-sm {
  padding-top: .5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-\[11px\] {
  font-size: 11px;
}

.text-\[13px\] {
  font-size: 13px;
}

.text-\[28px\] {
  font-size: 28px;
}

.text-\[35px\] {
  font-size: 35px;
}

.text-\[56px\] {
  font-size: 56px;
}

.text-\[8px\] {
  font-size: 8px;
}

.text-\[9px\] {
  font-size: 9px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.tracking-wide {
  letter-spacing: .025em;
}

.\!text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1)) !important;
}

.text-\[\#009B77\] {
  --tw-text-opacity: 1;
  color: rgb(0 155 119 / var(--tw-text-opacity, 1));
}

.text-\[\#009CBD\] {
  --tw-text-opacity: 1;
  color: rgb(0 156 189 / var(--tw-text-opacity, 1));
}

.text-\[\#00a480\] {
  --tw-text-opacity: 1;
  color: rgb(0 164 128 / var(--tw-text-opacity, 1));
}

.text-\[\#01519c\] {
  --tw-text-opacity: 1;
  color: rgb(1 81 156 / var(--tw-text-opacity, 1));
}

.text-\[\#059a7b\] {
  --tw-text-opacity: 1;
  color: rgb(5 154 123 / var(--tw-text-opacity, 1));
}

.text-\[\#1E3A8A\] {
  --tw-text-opacity: 1;
  color: rgb(30 58 138 / var(--tw-text-opacity, 1));
}

.text-\[\#1F4388\] {
  --tw-text-opacity: 1;
  color: rgb(31 67 136 / var(--tw-text-opacity, 1));
}

.text-\[\#1c4489\] {
  --tw-text-opacity: 1;
  color: rgb(28 68 137 / var(--tw-text-opacity, 1));
}

.text-\[\#33b997\] {
  --tw-text-opacity: 1;
  color: rgb(51 185 151 / var(--tw-text-opacity, 1));
}

.text-\[\#3a56ad\] {
  --tw-text-opacity: 1;
  color: rgb(58 86 173 / var(--tw-text-opacity, 1));
}

.text-\[\#73665f\] {
  --tw-text-opacity: 1;
  color: rgb(115 102 95 / var(--tw-text-opacity, 1));
}

.text-\[\#746e65\] {
  --tw-text-opacity: 1;
  color: rgb(116 110 101 / var(--tw-text-opacity, 1));
}

.text-\[\#756861\] {
  --tw-text-opacity: 1;
  color: rgb(117 104 97 / var(--tw-text-opacity, 1));
}

.text-\[\#808185\] {
  --tw-text-opacity: 1;
  color: rgb(128 129 133 / var(--tw-text-opacity, 1));
}

.text-\[\#84cc16\] {
  --tw-text-opacity: 1;
  color: rgb(132 204 22 / var(--tw-text-opacity, 1));
}

.text-\[\#97D700\] {
  --tw-text-opacity: 1;
  color: rgb(151 215 0 / var(--tw-text-opacity, 1));
}

.text-\[\#9a918c\] {
  --tw-text-opacity: 1;
  color: rgb(154 145 140 / var(--tw-text-opacity, 1));
}

.text-\[\#9f9792\] {
  --tw-text-opacity: 1;
  color: rgb(159 151 146 / var(--tw-text-opacity, 1));
}

.text-\[\#B9DCD2\] {
  --tw-text-opacity: 1;
  color: rgb(185 220 210 / var(--tw-text-opacity, 1));
}

.text-\[\#E15B2A\] {
  --tw-text-opacity: 1;
  color: rgb(225 91 42 / var(--tw-text-opacity, 1));
}

.text-\[\#E56A54\] {
  --tw-text-opacity: 1;
  color: rgb(229 106 84 / var(--tw-text-opacity, 1));
}

.text-\[\#E6A65D\] {
  --tw-text-opacity: 1;
  color: rgb(230 166 93 / var(--tw-text-opacity, 1));
}

.text-\[\#e77b24\] {
  --tw-text-opacity: 1;
  color: rgb(231 123 36 / var(--tw-text-opacity, 1));
}

.text-\[\#f3a00f\] {
  --tw-text-opacity: 1;
  color: rgb(243 160 15 / var(--tw-text-opacity, 1));
}

.text-\[\#f78e39\] {
  --tw-text-opacity: 1;
  color: rgb(247 142 57 / var(--tw-text-opacity, 1));
}

.text-\[\#f97316\] {
  --tw-text-opacity: 1;
  color: rgb(249 115 22 / var(--tw-text-opacity, 1));
}

.text-\[\#f9b516\] {
  --tw-text-opacity: 1;
  color: rgb(249 181 22 / var(--tw-text-opacity, 1));
}

.text-amber-500 {
  --tw-text-opacity: 1;
  color: rgb(245 158 11 / var(--tw-text-opacity, 1));
}

.text-amber-600 {
  --tw-text-opacity: 1;
  color: rgb(217 119 6 / var(--tw-text-opacity, 1));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.text-blue-400 {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity, 1));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity, 1));
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity, 1));
}

.text-blue-700 {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity, 1));
}

.text-blue-800 {
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity, 1));
}

.text-blue-900 {
  --tw-text-opacity: 1;
  color: rgb(30 58 138 / var(--tw-text-opacity, 1));
}

.text-cyan-500 {
  --tw-text-opacity: 1;
  color: rgb(6 182 212 / var(--tw-text-opacity, 1));
}

.text-cyan-600 {
  --tw-text-opacity: 1;
  color: rgb(8 145 178 / var(--tw-text-opacity, 1));
}

.text-emerald-500 {
  --tw-text-opacity: 1;
  color: rgb(16 185 129 / var(--tw-text-opacity, 1));
}

.text-emerald-600 {
  --tw-text-opacity: 1;
  color: rgb(5 150 105 / var(--tw-text-opacity, 1));
}

.text-fuchsia-500 {
  --tw-text-opacity: 1;
  color: rgb(217 70 239 / var(--tw-text-opacity, 1));
}

.text-fuchsia-600 {
  --tw-text-opacity: 1;
  color: rgb(192 38 211 / var(--tw-text-opacity, 1));
}

.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity, 1));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
}

.text-gray-50 {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity, 1));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity, 1));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity, 1));
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity, 1));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity, 1));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity, 1));
}

.text-green-700 {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity, 1));
}

.text-green-800 {
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity, 1));
}

.text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity, 1));
}

.text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity, 1));
}

.text-lime-500 {
  --tw-text-opacity: 1;
  color: rgb(132 204 22 / var(--tw-text-opacity, 1));
}

.text-lime-600 {
  --tw-text-opacity: 1;
  color: rgb(101 163 13 / var(--tw-text-opacity, 1));
}

.text-lime-800 {
  --tw-text-opacity: 1;
  color: rgb(63 98 18 / var(--tw-text-opacity, 1));
}

.text-lime-900 {
  --tw-text-opacity: 1;
  color: rgb(54 83 20 / var(--tw-text-opacity, 1));
}

.text-orange-400 {
  --tw-text-opacity: 1;
  color: rgb(251 146 60 / var(--tw-text-opacity, 1));
}

.text-orange-500 {
  --tw-text-opacity: 1;
  color: rgb(249 115 22 / var(--tw-text-opacity, 1));
}

.text-orange-600 {
  --tw-text-opacity: 1;
  color: rgb(234 88 12 / var(--tw-text-opacity, 1));
}

.text-orange-700 {
  --tw-text-opacity: 1;
  color: rgb(194 65 12 / var(--tw-text-opacity, 1));
}

.text-pink-500 {
  --tw-text-opacity: 1;
  color: rgb(236 72 153 / var(--tw-text-opacity, 1));
}

.text-pink-600 {
  --tw-text-opacity: 1;
  color: rgb(219 39 119 / var(--tw-text-opacity, 1));
}

.text-ps-gray {
  --tw-text-opacity: 1;
  color: rgb(129 130 133 / var(--tw-text-opacity, 1));
}

.text-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity, 1));
}

.text-purple-600 {
  --tw-text-opacity: 1;
  color: rgb(147 51 234 / var(--tw-text-opacity, 1));
}

.text-purple-800 {
  --tw-text-opacity: 1;
  color: rgb(107 33 168 / var(--tw-text-opacity, 1));
}

.text-red-300 {
  --tw-text-opacity: 1;
  color: rgb(252 165 165 / var(--tw-text-opacity, 1));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity, 1));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity, 1));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity, 1));
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity, 1));
}

.text-red-800 {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity, 1));
}

.text-red-900 {
  --tw-text-opacity: 1;
  color: rgb(127 29 29 / var(--tw-text-opacity, 1));
}

.text-rose-500 {
  --tw-text-opacity: 1;
  color: rgb(244 63 94 / var(--tw-text-opacity, 1));
}

.text-rose-600 {
  --tw-text-opacity: 1;
  color: rgb(225 29 72 / var(--tw-text-opacity, 1));
}

.text-sky-500 {
  --tw-text-opacity: 1;
  color: rgb(14 165 233 / var(--tw-text-opacity, 1));
}

.text-sky-600 {
  --tw-text-opacity: 1;
  color: rgb(2 132 199 / var(--tw-text-opacity, 1));
}

.text-slate-400 {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity, 1));
}

.text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity, 1));
}

.text-slate-600 {
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity, 1));
}

.text-slate-700 {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity, 1));
}

.text-slate-800 {
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity, 1));
}

.text-slate-900 {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity, 1));
}

.text-stone-500 {
  --tw-text-opacity: 1;
  color: rgb(120 113 108 / var(--tw-text-opacity, 1));
}

.text-stone-600 {
  --tw-text-opacity: 1;
  color: rgb(87 83 78 / var(--tw-text-opacity, 1));
}

.text-stone-800 {
  --tw-text-opacity: 1;
  color: rgb(41 37 36 / var(--tw-text-opacity, 1));
}

.text-stone-900 {
  --tw-text-opacity: 1;
  color: rgb(28 25 23 / var(--tw-text-opacity, 1));
}

.text-teal-500 {
  --tw-text-opacity: 1;
  color: rgb(20 184 166 / var(--tw-text-opacity, 1));
}

.text-teal-600 {
  --tw-text-opacity: 1;
  color: rgb(13 148 136 / var(--tw-text-opacity, 1));
}

.text-teal-700 {
  --tw-text-opacity: 1;
  color: rgb(15 118 110 / var(--tw-text-opacity, 1));
}

.text-violet-500 {
  --tw-text-opacity: 1;
  color: rgb(139 92 246 / var(--tw-text-opacity, 1));
}

.text-violet-600 {
  --tw-text-opacity: 1;
  color: rgb(124 58 237 / var(--tw-text-opacity, 1));
}

.text-violet-700 {
  --tw-text-opacity: 1;
  color: rgb(109 40 217 / var(--tw-text-opacity, 1));
}

.text-violet-800 {
  --tw-text-opacity: 1;
  color: rgb(91 33 182 / var(--tw-text-opacity, 1));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgb(234 179 8 / var(--tw-text-opacity, 1));
}

.text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgb(202 138 4 / var(--tw-text-opacity, 1));
}

.underline {
  text-decoration-line: underline;
}

.line-through {
  text-decoration-line: line-through;
}

.no-underline {
  text-decoration-line: none;
}

.placeholder-blue-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(30 58 138 / var(--tw-placeholder-opacity, 1));
}

.placeholder-gray-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(156 163 175 / var(--tw-placeholder-opacity, 1));
}

.placeholder-gray-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(107 114 128 / var(--tw-placeholder-opacity, 1));
}

.placeholder-gray-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(17 24 39 / var(--tw-placeholder-opacity, 1));
}

.placeholder-red-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(248 113 113 / var(--tw-placeholder-opacity, 1));
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-50 {
  opacity: .5;
}

.opacity-75 {
  opacity: .75;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_2px_4px_rgba\(0\,0\,0\,0\.1\)\] {
  --tw-shadow: 0 2px 4px #0000001a;
  --tw-shadow-colored: 0 2px 4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_2px_4px_rgba\(0\,0\,0\,0\.2\)\] {
  --tw-shadow: 0 2px 4px #0003;
  --tw-shadow-colored: 0 2px 4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline {
  outline-style: solid;
}

.outline-1 {
  outline-width: 1px;
}

.outline-\[\#73665f\] {
  outline-color: #73665f;
}

.outline-gray-400 {
  outline-color: #9ca3af;
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity, 1));
}

.ring-gray-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity, 1));
}

.ring-opacity-5 {
  --tw-ring-opacity: .05;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-md {
  --tw-blur: blur(12px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-100 {
  transition-duration: .1s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.duration-700 {
  transition-duration: .7s;
}

.duration-75 {
  transition-duration: 75ms;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.\[abc\:def\] {
  abc: def;
}

.\[key\:value\] {
  key: value;
}

.file\:mr-4::file-selector-button {
  margin-right: 1rem;
}

.file\:rounded-md::file-selector-button {
  border-radius: .375rem;
}

.file\:border-0::file-selector-button {
  border-width: 0;
}

.file\:bg-gray-800::file-selector-button {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.file\:px-psc-base::file-selector-button {
  padding-left: 1rem;
  padding-right: 1rem;
}

.file\:py-2::file-selector-button {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.file\:text-sm::file-selector-button {
  font-size: .875rem;
  line-height: 1.25rem;
}

.file\:font-semibold::file-selector-button {
  font-weight: 600;
}

.file\:text-white::file-selector-button {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.last\:mb-0:last-child {
  margin-bottom: 0;
}

.hover\:border-2:hover {
  border-width: 2px;
}

.hover\:border-4:hover {
  border-width: 4px;
}

.hover\:border-\[\#1E3A8A\]:hover {
  --tw-border-opacity: 1;
  border-color: rgb(30 58 138 / var(--tw-border-opacity, 1));
}

.hover\:border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
}

.hover\:border-lime-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(63 98 18 / var(--tw-border-opacity, 1));
}

.hover\:border-b-lime-700:hover {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(77 124 15 / var(--tw-border-opacity, 1));
}

.hover\:bg-\[\#1E3A8A\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(30 58 138 / var(--tw-bg-opacity, 1));
}

.hover\:bg-\[\#1F4388\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(31 67 136 / var(--tw-bg-opacity, 1));
}

.hover\:bg-\[\#E15B2A\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(225 91 42 / var(--tw-bg-opacity, 1));
}

.hover\:bg-amber-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(217 119 6 / var(--tw-bg-opacity, 1));
}

.hover\:bg-blue-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity, 1));
}

.hover\:bg-blue-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity, 1));
}

.hover\:bg-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity, 1));
}

.hover\:bg-blue-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(30 64 175 / var(--tw-bg-opacity, 1));
}

.hover\:bg-cyan-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(8 145 178 / var(--tw-bg-opacity, 1));
}

.hover\:bg-emerald-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(5 150 105 / var(--tw-bg-opacity, 1));
}

.hover\:bg-fuchsia-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(192 38 211 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.hover\:bg-green-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity, 1));
}

.hover\:bg-green-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity, 1));
}

.hover\:bg-green-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity, 1));
}

.hover\:bg-indigo-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity, 1));
}

.hover\:bg-lime-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(236 252 203 / var(--tw-bg-opacity, 1));
}

.hover\:bg-lime-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(217 249 157 / var(--tw-bg-opacity, 1));
}

.hover\:bg-lime-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(247 254 231 / var(--tw-bg-opacity, 1));
}

.hover\:bg-lime-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(101 163 13 / var(--tw-bg-opacity, 1));
}

.hover\:bg-lime-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(77 124 15 / var(--tw-bg-opacity, 1));
}

.hover\:bg-orange-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(234 88 12 / var(--tw-bg-opacity, 1));
}

.hover\:bg-pink-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(219 39 119 / var(--tw-bg-opacity, 1));
}

.hover\:bg-ps-blue-dark:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(20 50 102 / var(--tw-bg-opacity, 1));
}

.hover\:bg-purple-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(147 51 234 / var(--tw-bg-opacity, 1));
}

.hover\:bg-red-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity, 1));
}

.hover\:bg-red-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity, 1));
}

.hover\:bg-red-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity, 1));
}

.hover\:bg-rose-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(225 29 72 / var(--tw-bg-opacity, 1));
}

.hover\:bg-sky-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(2 132 199 / var(--tw-bg-opacity, 1));
}

.hover\:bg-slate-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity, 1));
}

.hover\:bg-teal-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(13 148 136 / var(--tw-bg-opacity, 1));
}

.hover\:bg-transparent:hover {
  background-color: #0000;
}

.hover\:bg-violet-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(124 58 237 / var(--tw-bg-opacity, 1));
}

.hover\:bg-yellow-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(202 138 4 / var(--tw-bg-opacity, 1));
}

.hover\:text-amber-700:hover {
  --tw-text-opacity: 1;
  color: rgb(180 83 9 / var(--tw-text-opacity, 1));
}

.hover\:text-blue-700:hover {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity, 1));
}

.hover\:text-blue-800:hover {
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity, 1));
}

.hover\:text-cyan-700:hover {
  --tw-text-opacity: 1;
  color: rgb(14 116 144 / var(--tw-text-opacity, 1));
}

.hover\:text-emerald-700:hover {
  --tw-text-opacity: 1;
  color: rgb(4 120 87 / var(--tw-text-opacity, 1));
}

.hover\:text-fuchsia-700:hover {
  --tw-text-opacity: 1;
  color: rgb(162 28 175 / var(--tw-text-opacity, 1));
}

.hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity, 1));
}

.hover\:text-green-600:hover {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity, 1));
}

.hover\:text-green-700:hover {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity, 1));
}

.hover\:text-indigo-700:hover {
  --tw-text-opacity: 1;
  color: rgb(67 56 202 / var(--tw-text-opacity, 1));
}

.hover\:text-lime-600:hover {
  --tw-text-opacity: 1;
  color: rgb(101 163 13 / var(--tw-text-opacity, 1));
}

.hover\:text-lime-700:hover {
  --tw-text-opacity: 1;
  color: rgb(77 124 15 / var(--tw-text-opacity, 1));
}

.hover\:text-orange-500:hover {
  --tw-text-opacity: 1;
  color: rgb(249 115 22 / var(--tw-text-opacity, 1));
}

.hover\:text-orange-700:hover {
  --tw-text-opacity: 1;
  color: rgb(194 65 12 / var(--tw-text-opacity, 1));
}

.hover\:text-pink-700:hover {
  --tw-text-opacity: 1;
  color: rgb(190 24 93 / var(--tw-text-opacity, 1));
}

.hover\:text-purple-700:hover {
  --tw-text-opacity: 1;
  color: rgb(126 34 206 / var(--tw-text-opacity, 1));
}

.hover\:text-red-700:hover {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity, 1));
}

.hover\:text-red-800:hover {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity, 1));
}

.hover\:text-rose-700:hover {
  --tw-text-opacity: 1;
  color: rgb(190 18 60 / var(--tw-text-opacity, 1));
}

.hover\:text-sky-700:hover {
  --tw-text-opacity: 1;
  color: rgb(3 105 161 / var(--tw-text-opacity, 1));
}

.hover\:text-slate-400:hover {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity, 1));
}

.hover\:text-teal-600:hover {
  --tw-text-opacity: 1;
  color: rgb(13 148 136 / var(--tw-text-opacity, 1));
}

.hover\:text-teal-700:hover {
  --tw-text-opacity: 1;
  color: rgb(15 118 110 / var(--tw-text-opacity, 1));
}

.hover\:text-violet-600:hover {
  --tw-text-opacity: 1;
  color: rgb(124 58 237 / var(--tw-text-opacity, 1));
}

.hover\:text-violet-700:hover {
  --tw-text-opacity: 1;
  color: rgb(109 40 217 / var(--tw-text-opacity, 1));
}

.hover\:text-yellow-700:hover {
  --tw-text-opacity: 1;
  color: rgb(161 98 7 / var(--tw-text-opacity, 1));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:shadow-\[0_8px_8px_rgba\(0\,0\,0\,0\.2\)\]:hover {
  --tw-shadow: 0 8px 8px #0003;
  --tw-shadow-colored: 0 8px 8px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:file\:bg-gray-700::file-selector-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.focus\:border-\[\#1F4388\]:focus {
  --tw-border-opacity: 1;
  border-color: rgb(31 67 136 / var(--tw-border-opacity, 1));
}

.focus\:border-amber-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(245 158 11 / var(--tw-border-opacity, 1));
}

.focus\:border-blue-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity, 1));
}

.focus\:border-blue-600:focus {
  --tw-border-opacity: 1;
  border-color: rgb(37 99 235 / var(--tw-border-opacity, 1));
}

.focus\:border-blue-900:focus {
  --tw-border-opacity: 1;
  border-color: rgb(30 58 138 / var(--tw-border-opacity, 1));
}

.focus\:border-cyan-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(6 182 212 / var(--tw-border-opacity, 1));
}

.focus\:border-emerald-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(16 185 129 / var(--tw-border-opacity, 1));
}

.focus\:border-fuchsia-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(217 70 239 / var(--tw-border-opacity, 1));
}

.focus\:border-gray-400:focus {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity, 1));
}

.focus\:border-green-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(34 197 94 / var(--tw-border-opacity, 1));
}

.focus\:border-indigo-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity, 1));
}

.focus\:border-lime-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(132 204 22 / var(--tw-border-opacity, 1));
}

.focus\:border-orange-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(249 115 22 / var(--tw-border-opacity, 1));
}

.focus\:border-pink-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(236 72 153 / var(--tw-border-opacity, 1));
}

.focus\:border-purple-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(168 85 247 / var(--tw-border-opacity, 1));
}

.focus\:border-red-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity, 1));
}

.focus\:border-rose-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(244 63 94 / var(--tw-border-opacity, 1));
}

.focus\:border-sky-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(14 165 233 / var(--tw-border-opacity, 1));
}

.focus\:border-teal-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(20 184 166 / var(--tw-border-opacity, 1));
}

.focus\:border-violet-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(139 92 246 / var(--tw-border-opacity, 1));
}

.focus\:border-yellow-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(234 179 8 / var(--tw-border-opacity, 1));
}

.focus\:border-b-lime-700:focus {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(77 124 15 / var(--tw-border-opacity, 1));
}

.focus\:bg-amber-100\/50:focus {
  background-color: #fef3c780;
}

.focus\:bg-blue-100\/50:focus {
  background-color: #dbeafe80;
}

.focus\:bg-cyan-100\/50:focus {
  background-color: #cffafe80;
}

.focus\:bg-emerald-100\/50:focus {
  background-color: #d1fae580;
}

.focus\:bg-fuchsia-100\/50:focus {
  background-color: #fae8ff80;
}

.focus\:bg-green-100\/50:focus {
  background-color: #dcfce780;
}

.focus\:bg-indigo-100\/50:focus {
  background-color: #e0e7ff80;
}

.focus\:bg-lime-100\/50:focus {
  background-color: #ecfccb80;
}

.focus\:bg-orange-100\/50:focus {
  background-color: #ffedd580;
}

.focus\:bg-pink-100\/50:focus {
  background-color: #fce7f380;
}

.focus\:bg-purple-100\/50:focus {
  background-color: #f3e8ff80;
}

.focus\:bg-red-100\/50:focus {
  background-color: #fee2e280;
}

.focus\:bg-rose-100\/50:focus {
  background-color: #ffe4e680;
}

.focus\:bg-sky-100\/50:focus {
  background-color: #e0f2fe80;
}

.focus\:bg-teal-100\/50:focus {
  background-color: #ccfbf180;
}

.focus\:bg-transparent:focus {
  background-color: #0000;
}

.focus\:bg-violet-100\/50:focus {
  background-color: #ede9fe80;
}

.focus\:bg-yellow-100\/50:focus {
  background-color: #fef9c380;
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-1:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-4:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-\[\#1F4388\]:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(31 67 136 / var(--tw-ring-opacity, 1));
}

.focus\:ring-amber-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(245 158 11 / var(--tw-ring-opacity, 1));
}

.focus\:ring-amber-500\/20:focus {
  --tw-ring-color: #f59e0b33;
}

.focus\:ring-amber-500\/50:focus {
  --tw-ring-color: #f59e0b80;
}

.focus\:ring-blue-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity, 1));
}

.focus\:ring-blue-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity, 1));
}

.focus\:ring-blue-500\/20:focus {
  --tw-ring-color: #3b82f633;
}

.focus\:ring-blue-500\/50:focus {
  --tw-ring-color: #3b82f680;
}

.focus\:ring-blue-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(29 78 216 / var(--tw-ring-opacity, 1));
}

.focus\:ring-blue-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(30 58 138 / var(--tw-ring-opacity, 1));
}

.focus\:ring-cyan-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(6 182 212 / var(--tw-ring-opacity, 1));
}

.focus\:ring-cyan-500\/20:focus {
  --tw-ring-color: #06b6d433;
}

.focus\:ring-cyan-500\/50:focus {
  --tw-ring-color: #06b6d480;
}

.focus\:ring-emerald-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(16 185 129 / var(--tw-ring-opacity, 1));
}

.focus\:ring-emerald-500\/20:focus {
  --tw-ring-color: #10b98133;
}

.focus\:ring-emerald-500\/50:focus {
  --tw-ring-color: #10b98180;
}

.focus\:ring-fuchsia-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(217 70 239 / var(--tw-ring-opacity, 1));
}

.focus\:ring-fuchsia-500\/20:focus {
  --tw-ring-color: #d946ef33;
}

.focus\:ring-fuchsia-500\/50:focus {
  --tw-ring-color: #d946ef80;
}

.focus\:ring-gray-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(243 244 246 / var(--tw-ring-opacity, 1));
}

.focus\:ring-gray-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity, 1));
}

.focus\:ring-gray-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity, 1));
}

.focus\:ring-gray-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(107 114 128 / var(--tw-ring-opacity, 1));
}

.focus\:ring-gray-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(55 65 81 / var(--tw-ring-opacity, 1));
}

.focus\:ring-gray-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(17 24 39 / var(--tw-ring-opacity, 1));
}

.focus\:ring-green-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(34 197 94 / var(--tw-ring-opacity, 1));
}

.focus\:ring-green-500\/20:focus {
  --tw-ring-color: #22c55e33;
}

.focus\:ring-green-500\/50:focus {
  --tw-ring-color: #22c55e80;
}

.focus\:ring-green-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(22 163 74 / var(--tw-ring-opacity, 1));
}

.focus\:ring-green-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(21 128 61 / var(--tw-ring-opacity, 1));
}

.focus\:ring-indigo-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity, 1));
}

.focus\:ring-indigo-500\/20:focus {
  --tw-ring-color: #6366f133;
}

.focus\:ring-indigo-500\/50:focus {
  --tw-ring-color: #6366f180;
}

.focus\:ring-lime-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(132 204 22 / var(--tw-ring-opacity, 1));
}

.focus\:ring-lime-500\/20:focus {
  --tw-ring-color: #84cc1633;
}

.focus\:ring-lime-500\/50:focus {
  --tw-ring-color: #84cc1680;
}

.focus\:ring-lime-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(77 124 15 / var(--tw-ring-opacity, 1));
}

.focus\:ring-orange-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(249 115 22 / var(--tw-ring-opacity, 1));
}

.focus\:ring-orange-500\/20:focus {
  --tw-ring-color: #f9731633;
}

.focus\:ring-orange-500\/50:focus {
  --tw-ring-color: #f9731680;
}

.focus\:ring-pink-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(236 72 153 / var(--tw-ring-opacity, 1));
}

.focus\:ring-pink-500\/20:focus {
  --tw-ring-color: #ec489933;
}

.focus\:ring-pink-500\/50:focus {
  --tw-ring-color: #ec489980;
}

.focus\:ring-ps-blue:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(28 68 137 / var(--tw-ring-opacity, 1));
}

.focus\:ring-purple-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(168 85 247 / var(--tw-ring-opacity, 1));
}

.focus\:ring-purple-500\/20:focus {
  --tw-ring-color: #a855f733;
}

.focus\:ring-purple-500\/50:focus {
  --tw-ring-color: #a855f780;
}

.focus\:ring-red-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity, 1));
}

.focus\:ring-red-500\/20:focus {
  --tw-ring-color: #ef444433;
}

.focus\:ring-red-500\/50:focus {
  --tw-ring-color: #ef444480;
}

.focus\:ring-red-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(220 38 38 / var(--tw-ring-opacity, 1));
}

.focus\:ring-red-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(185 28 28 / var(--tw-ring-opacity, 1));
}

.focus\:ring-rose-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(244 63 94 / var(--tw-ring-opacity, 1));
}

.focus\:ring-rose-500\/20:focus {
  --tw-ring-color: #f43f5e33;
}

.focus\:ring-rose-500\/50:focus {
  --tw-ring-color: #f43f5e80;
}

.focus\:ring-sky-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(14 165 233 / var(--tw-ring-opacity, 1));
}

.focus\:ring-sky-500\/20:focus {
  --tw-ring-color: #0ea5e933;
}

.focus\:ring-sky-500\/50:focus {
  --tw-ring-color: #0ea5e980;
}

.focus\:ring-teal-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(20 184 166 / var(--tw-ring-opacity, 1));
}

.focus\:ring-teal-500\/20:focus {
  --tw-ring-color: #14b8a633;
}

.focus\:ring-teal-500\/50:focus {
  --tw-ring-color: #14b8a680;
}

.focus\:ring-violet-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(139 92 246 / var(--tw-ring-opacity, 1));
}

.focus\:ring-violet-500\/20:focus {
  --tw-ring-color: #8b5cf633;
}

.focus\:ring-violet-500\/50:focus {
  --tw-ring-color: #8b5cf680;
}

.focus\:ring-yellow-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(234 179 8 / var(--tw-ring-opacity, 1));
}

.focus\:ring-yellow-500\/20:focus {
  --tw-ring-color: #eab30833;
}

.focus\:ring-yellow-500\/50:focus {
  --tw-ring-color: #eab30880;
}

.focus\:ring-offset-0:focus {
  --tw-ring-offset-width: 0px;
}

.focus\:ring-offset-1:focus {
  --tw-ring-offset-width: 1px;
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.focus\:ring-offset-green-50:focus {
  --tw-ring-offset-color: #f0fdf4;
}

.focus\:ring-offset-red-50:focus {
  --tw-ring-offset-color: #fef2f2;
}

.focus-visible\:outline-offset-\[-4px\]:focus-visible {
  outline-offset: -4px;
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:opacity-40:disabled {
  opacity: .4;
}

.group:hover .group-hover\:inline-flex {
  display: inline-flex;
}

.dark\:divide-gray-600:is(.dark *) > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-divide-opacity, 1));
}

.dark\:border-gray-600:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity, 1));
}

.dark\:border-gray-700:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity, 1));
}

.dark\:border-slate-600:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(71 85 105 / var(--tw-border-opacity, 1));
}

.dark\:bg-blue-600:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity, 1));
}

.dark\:bg-gray-700:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.dark\:bg-slate-800:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity, 1));
}

.dark\:bg-white\/10:is(.dark *) {
  background-color: #ffffff1a;
}

.dark\:bg-white\/5:is(.dark *) {
  background-color: #ffffff0d;
}

.dark\:text-amber-400:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(251 191 36 / var(--tw-text-opacity, 1));
}

.dark\:text-blue-400:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity, 1));
}

.dark\:text-cyan-400:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
}

.dark\:text-emerald-400:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(52 211 153 / var(--tw-text-opacity, 1));
}

.dark\:text-fuchsia-400:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(232 121 249 / var(--tw-text-opacity, 1));
}

.dark\:text-gray-400:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
}

.dark\:text-gray-700:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}

.dark\:text-green-400:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity, 1));
}

.dark\:text-indigo-400:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(129 140 248 / var(--tw-text-opacity, 1));
}

.dark\:text-lime-400:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(163 230 53 / var(--tw-text-opacity, 1));
}

.dark\:text-orange-400:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(251 146 60 / var(--tw-text-opacity, 1));
}

.dark\:text-pink-400:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(244 114 182 / var(--tw-text-opacity, 1));
}

.dark\:text-purple-400:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(192 132 252 / var(--tw-text-opacity, 1));
}

.dark\:text-red-400:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity, 1));
}

.dark\:text-rose-400:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(251 113 133 / var(--tw-text-opacity, 1));
}

.dark\:text-sky-400:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(56 189 248 / var(--tw-text-opacity, 1));
}

.dark\:text-teal-400:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(45 212 191 / var(--tw-text-opacity, 1));
}

.dark\:text-violet-400:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(167 139 250 / var(--tw-text-opacity, 1));
}

.dark\:text-white:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.dark\:text-white\/70:is(.dark *) {
  color: #ffffffb3;
}

.dark\:text-white\/80:is(.dark *) {
  color: #fffc;
}

.dark\:text-yellow-400:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(250 204 21 / var(--tw-text-opacity, 1));
}

.dark\:placeholder-gray-400:is(.dark *)::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(156 163 175 / var(--tw-placeholder-opacity, 1));
}

.dark\:hover\:bg-blue-700:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity, 1));
}

.dark\:hover\:bg-gray-600:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity, 1));
}

.dark\:hover\:bg-gray-700:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.dark\:hover\:bg-white\/10:hover:is(.dark *) {
  background-color: #ffffff1a;
}

.dark\:hover\:text-amber-400:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(251 191 36 / var(--tw-text-opacity, 1));
}

.dark\:hover\:text-blue-400:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity, 1));
}

.dark\:hover\:text-cyan-400:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity, 1));
}

.dark\:hover\:text-emerald-400:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(52 211 153 / var(--tw-text-opacity, 1));
}

.dark\:hover\:text-fuchsia-400:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(232 121 249 / var(--tw-text-opacity, 1));
}

.dark\:hover\:text-green-400:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity, 1));
}

.dark\:hover\:text-indigo-400:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(129 140 248 / var(--tw-text-opacity, 1));
}

.dark\:hover\:text-lime-400:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(163 230 53 / var(--tw-text-opacity, 1));
}

.dark\:hover\:text-orange-400:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(251 146 60 / var(--tw-text-opacity, 1));
}

.dark\:hover\:text-pink-400:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(244 114 182 / var(--tw-text-opacity, 1));
}

.dark\:hover\:text-purple-400:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(192 132 252 / var(--tw-text-opacity, 1));
}

.dark\:hover\:text-red-400:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity, 1));
}

.dark\:hover\:text-rose-400:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(251 113 133 / var(--tw-text-opacity, 1));
}

.dark\:hover\:text-sky-400:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(56 189 248 / var(--tw-text-opacity, 1));
}

.dark\:hover\:text-teal-400:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(45 212 191 / var(--tw-text-opacity, 1));
}

.dark\:hover\:text-violet-400:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(167 139 250 / var(--tw-text-opacity, 1));
}

.dark\:hover\:text-white:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.dark\:hover\:text-yellow-400:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(250 204 21 / var(--tw-text-opacity, 1));
}

.dark\:focus\:border-blue-500:focus:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity, 1));
}

.dark\:focus\:bg-white\/10:focus:is(.dark *) {
  background-color: #ffffff1a;
}

.dark\:focus\:ring-blue-500:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity, 1));
}

@media (width >= 640px) {
  .sm\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .sm\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:block {
    display: block;
  }

  .sm\:grid {
    display: grid;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:max-w-2xl {
    max-width: 42rem;
  }

  .sm\:max-w-4xl {
    max-width: 56rem;
  }

  .sm\:max-w-xl {
    max-width: 36rem;
  }

  .sm\:translate-x-0 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-2 {
    --tw-translate-x: .5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:items-start {
    align-items: flex-start;
  }

  .sm\:items-end {
    align-items: flex-end;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:gap-4 {
    gap: 1rem;
  }

  .sm\:rounded-lg {
    border-radius: .5rem;
  }

  .sm\:rounded-xl {
    border-radius: .75rem;
  }

  .sm\:p-0 {
    padding: 0;
  }

  .sm\:p-6 {
    padding: 1.5rem;
  }

  .sm\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .sm\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .sm\:pl-4 {
    padding-left: 1rem;
  }

  .sm\:pr-3\.5 {
    padding-right: .875rem;
  }

  .sm\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .sm\:duration-700 {
    transition-duration: .7s;
  }
}

@media (width >= 768px) {
  .md\:block {
    display: block;
  }

  .md\:w-1\/3 {
    width: 33.3333%;
  }

  .md\:w-\[296px\] {
    width: 296px;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:min-w-\[296px\] {
    min-width: 296px;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.375rem * var(--tw-space-x-reverse));
    margin-left: calc(.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .md\:border-b {
    border-bottom-width: 1px;
  }

  .md\:pl-1 {
    padding-left: .25rem;
  }

  .md\:pl-2 {
    padding-left: .5rem;
  }

  .tablet\:block {
    display: block;
  }

  .tablet\:rounded-xl {
    border-radius: .75rem;
  }

  .tablet\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .tablet\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .tablet\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .tablet\:pr-10 {
    padding-right: 2.5rem;
  }
}

@media (width >= 1024px) {
  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:h-10 {
    height: 2.5rem;
  }

  .lg\:w-10 {
    width: 2.5rem;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:border-b-0 {
    border-bottom-width: 0;
  }

  .lg\:border-r {
    border-right-width: 1px;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:pb-0 {
    padding-bottom: 0;
  }

  .lg\:pr-1 {
    padding-right: .25rem;
  }

  .lg\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }

  .laptop\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .laptop\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .laptop\:mt-\[140px\] {
    margin-top: 140px;
  }

  .laptop\:grid {
    display: grid;
  }

  .laptop\:w-4\/12 {
    width: 33.3333%;
  }

  .laptop\:w-5\/12 {
    width: 41.6667%;
  }

  .laptop\:pt-0 {
    padding-top: 0;
  }
}

@media (width >= 1280px) {
  .wide\:-bottom-40 {
    bottom: -10rem;
  }

  .wide\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .wide\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .wide\:col-start-2 {
    grid-column-start: 2;
  }

  .wide\:mb-0 {
    margin-bottom: 0;
  }

  .wide\:mb-psc-xl {
    margin-bottom: 2rem;
  }

  .wide\:ml-0 {
    margin-left: 0;
  }

  .wide\:mt-0 {
    margin-top: 0;
  }

  .wide\:mt-44 {
    margin-top: 11rem;
  }

  .wide\:mt-\[180px\] {
    margin-top: 180px;
  }

  .wide\:grid {
    display: grid;
  }

  .wide\:w-1\/4 {
    width: 25%;
  }

  .wide\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .wide\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .wide\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .wide\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .wide\:pl-0 {
    padding-left: 0;
  }

  .wide\:pl-\[75px\] {
    padding-left: 75px;
  }

  .wide\:pr-psc-base {
    padding-right: 1rem;
  }
}

@media (width >= 1536px) {
  .\32 xl\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .widest\:-bottom-32 {
    bottom: -8rem;
  }

  .widest\:top-28 {
    top: 7rem;
  }

  .widest\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .widest\:ml-psc-base {
    margin-left: 1rem;
  }

  .widest\:mt-0 {
    margin-top: 0;
  }

  .widest\:mt-36 {
    margin-top: 9rem;
  }
}
/*# sourceMappingURL=index.8b352daf.css.map */
